import { register as registerSwiperElements } from 'swiper/element/bundle';

import * as Sentry from "@sentry/angular";
import { environment } from './environments/environment';
import { bootstrapApplication } from '@angular/platform-browser';
import { AppComponent } from './app/app.component';
import { PreloadAllModules, provideRouter, Router, RouteReuseStrategy, withPreloading } from '@angular/router';
import { routes } from './app/app.routes';
import { IonicRouteStrategy, provideIonicAngular } from '@ionic/angular/standalone';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, provideExperimentalZonelessChangeDetection } from '@angular/core';
import { AllCommunityModule, ModuleRegistry } from 'ag-grid-community';

if (environment.production) {
  Sentry.init({
    dsn: "https://efbd3b2f3c93355237552a8e85655cb6@o4508795761131520.ingest.us.sentry.io/4508795792064512",
    integrations: [
      Sentry.browserTracingIntegration(),
      // Sentry.replayIntegration(), // this causes significant memory leaks, i.e. detached DOM nodes
    ],
    // Tracing
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    //https://eqnalgygsaemozcinpyb.supabase.co/functions/v1/api
    tracePropagationTargets: [/^https:\/\/eqnalgygsaemozcinpyb.supabase.co\/functions\/v1\/api/],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

ModuleRegistry.registerModules([AllCommunityModule]);
registerSwiperElements();
bootstrapApplication(AppComponent, {
  providers: [
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    provideIonicAngular({ useSetInputAPI: true, animated: true }),
    provideRouter(routes, withPreloading(PreloadAllModules)),
    provideHttpClient(withInterceptorsFromDi()),
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => { },
      deps: [Sentry.TraceService],
      multi: true,
    },
    provideExperimentalZonelessChangeDetection(),
  ]
});