import { Routes } from '@angular/router';
import { Tab } from './types/tab';
import { authGuard } from './guards/auth.guard';
import { unauthGuard } from './guards/unauth.guard';
import { upgradeResolver } from './resolvers/upgrade.resolver';
import { subscriptionGuard } from './guards/subscription.guard';
import { entityGuard } from './guards/entity.guard';
import { adminGuard } from './guards/admin.guard';
import { staffGuard } from './guards/staff.guard';

export const routes: Routes = [
  { path: '', loadComponent: () => import('./components/home/home.component').then(c => c.HomeComponent), data: { tab: Tab.Home } },
  { path: 'auth/signup', loadComponent: () => import('./components/auth/signup/signup.component').then(c => c.SignupComponent), data: { tab: Tab.None }, canActivate: [unauthGuard] },
  { path: 'auth/login', loadComponent: () => import('./components/auth/login/login.component').then(c => c.LoginComponent), data: { tab: Tab.None }, canActivate: [unauthGuard] },
  { path: 'auth/enter-id', loadComponent: () => import('./components/auth/forgot-password-enter-email/forgot-password-enter-email.component').then(c => c.ForgotPasswordEnterEmailComponent), data: { tab: Tab.None }, canActivate: [unauthGuard] },
  { path: 'auth/verify-otp', loadComponent: () => import('./components/auth/forgot-password-verify-otp/forgot-password-verify-otp.component').then(c => c.ForgotPasswordVerifyOtpComponent), data: { tab: Tab.None }, canActivate: [unauthGuard] },
  { path: 'auth/reset-password', loadComponent: () => import('./components/auth/update-password/update-password.component').then(c => c.UpdatePasswordComponent), data: { tab: Tab.None } },
  { path: 'auth/confirm-email', loadComponent: () => import('./components/auth/confirm-email/confirm-email.component').then(c => c.ConfirmEmailComponent), data: { tab: Tab.None } },
  { path: 'user/upgrade', loadComponent: () => import('./components/entity/upgrade/upgrade.component').then(c => c.UpgradeComponent), data: { tab: Tab.None }, canActivate: [authGuard], resolve: { promoCode: upgradeResolver } },
  { path: 'user/pay', loadComponent: () => import('./components/take-payment/take-payment.component').then(c => c.TakePaymentComponent), data: { tab: Tab.None }, canActivate: [authGuard] },
  {
    path: 'dashboard',
    loadComponent: () => import('./components/dashboard/dashboard.component').then(c => c.DashboardPage),
    data: { tab: Tab.Business },
    children: [
      { path: '', redirectTo: 'pages', pathMatch: 'full', data: { tab: Tab.Business } },
      { path: 'pages', loadComponent: () => import('./components/dashboard/pages/pages.component').then(c => c.PagesComponent), data: { tab: Tab.Business }, canActivate: [authGuard] },
      { path: 'pages/:entityId', loadComponent: () => import('./components/entity/entity-read/entity-read.component').then(c => c.EntityReadComponent), data: { tab: Tab.Business } },
      { path: 'pages/:entityId/edit', loadComponent: () => import('./components/entity/entity-edit/entity-edit.component').then(c => c.EntityEditComponent), data: { tab: Tab.Business }, canActivate: [authGuard, entityGuard, subscriptionGuard] },
      { path: 'pages/:entityId/claim', loadComponent: () => import('./components/entity/entity-claim-verify/entity-claim-verify.component').then(c => c.EntityClaimVerifyComponent), data: { tab: Tab.Business }, canActivate: [authGuard] },
      { path: 'subscription', loadComponent: () => import('./components/dashboard/subscription/subscription.component').then(c => c.SubscriptionComponent), data: { tab: Tab.Business }, canActivate: [entityGuard] },
      { path: 'settings', loadComponent: () => import('./components/dashboard/settings/settings.component').then(c => c.SettingsComponent), data: { tab: Tab.Business }, canActivate: [entityGuard] },
      { path: 'support', loadComponent: () => import('./components/dashboard/support/support.component').then(c => c.SupportComponent), data: { tab: Tab.Business }, canActivate: [entityGuard] },
      { path: 'users', loadComponent: () => import('./components/dashboard/admin/users/users.component').then(c => c.UsersComponent), data: { tab: Tab.Admin } },
      { path: 'users/:userId', loadComponent: () => import('./components/dashboard/admin/users/user-details/user-details.component').then(c => c.UserDetailsComponent), data: { tab: Tab.Admin }, canActivate: [adminGuard] },
      { path: 'export', loadComponent: () => import('./components/dashboard/admin/export/export.component').then(c => c.ExportComponent), data: { tab: Tab.Admin }, canActivate: [adminGuard] },
      { path: 'bulk-pages', loadComponent: () => import('./components/dashboard/bulk-pages/bulk-pages.component').then(c => c.BulkPagesComponent), data: { tab: Tab.Admin }, canActivate: [staffGuard] }
    ]
  },
  { path: 'admin', loadComponent: () => import('./components/dashboard/admin/admin.component').then(c => c.AdminComponent), data: { tab: Tab.Admin }, canActivate: [adminGuard] },
  { path: "admin/users", loadComponent: () => import('./components/dashboard/admin/users/users.component').then(c => c.UsersComponent), data: { tab: Tab.Admin }, canActivate: [adminGuard] },
  { path: "admin/users/:userId", loadComponent: () => import('./components/dashboard/admin/users/user-details/user-details.component').then(c => c.UserDetailsComponent), data: { tab: Tab.Admin }, canActivate: [adminGuard] },
  { path: "admin/export", loadComponent: () => import('./components/dashboard/admin/export/export.component').then(c => c.ExportComponent), data: { tab: Tab.Admin }, canActivate: [adminGuard] },
  {
    path: 'user/account', loadComponent: () => import('./components/account-home/account-home.component').then(c => c.AccountHomeComponent), data: { tab: Tab.Account }, canActivate: [authGuard], children: [
      { path: '', redirectTo: 'profile', pathMatch: 'full' },
      { path: 'profile', loadComponent: () => import('./components/account-home/account-profile/account-profile.component').then(c => c.AccountProfileComponent), data: { tab: Tab.Account } },
      { path: 'password', loadComponent: () => import('./components/account-home/account-password/account-password.component').then(c => c.AccountPasswordComponent), data: { tab: Tab.Account } },
      { path: 'feedback', loadComponent: () => import('./components/account-home/account-feedback/account-feedback.component').then(c => c.AccountFeedbackComponent), data: { tab: Tab.Account } }
    ]
  },
  { path: ':entityId', loadComponent: () => import('./components/home/home.component').then(c => c.HomeComponent), data: { tab: Tab.Home } },
  { path: '**', loadComponent: () => import('./components/ui/not-found/not-found.component').then(c => c.NotFoundComponent), data: { tab: Tab.None } },
];